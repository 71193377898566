export default {
  ticketing: {
    title: '东京晴空塔® 门票（指定时间入场券）',
    ticketPrice: '票价',
    content: '门票内容',
    purchaseNotice: '购买须知',
    cancellationPolicy: '取消須知',
    meetingPoint: '地点说明',
    inventoryNotice: '库存須知',
    symbol: {
      plenty: '充足',
      few: '少量',
      none: '無库存'
    },
    options: 'Options',
    time: 'Time',
    calendarMsg: `※<spanRed>{{redColor}}</spanRed>：套票／<spanBlue>{{blueColor}}</spanBlue>：天望甲板成人票价。
    ※最多可提前 30 天预订。
    ※截至1天前购票最高立省JPY 400。`,
    redColor: '红字',
    blueColor: '蓝字',
    noSlotsAvailable: '非常抱歉，当前没有票券库存。',
    soldOut: '预定贩售当日票'
  },
  date: {
    month: {
      month1: 'Jan',
      month2: 'Feb',
      month3: 'Mar',
      month4: 'Apr',
      month5: 'May',
      month6: 'Jun',
      month7: 'Jul',
      month8: 'Aug',
      month9: 'Sep',
      month10: 'Oct',
      month11: 'Nov',
      month12: 'Dec'
    },
    weeks: {
      sun: 'Sun.',
      mon: 'Mon.',
      tue: 'Tue.',
      wed: 'Wed.',
      thu: 'Thu.',
      fri: 'Fri.',
      sat: 'Sat.'
    }
  }
};
