import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getValidLocale } from '@/utils/locale';
import { DEFAULT_LOCALE } from '@/constants/locale';
import enText from './lang/en';
import koText from './lang/ko';
import zhText from './lang/zh';
import ztText from './lang/zt';

export const resources = {
  en: {
    translation: enText
  },
  ko: {
    translation: koText
  },
  zh: {
    translation: zhText
  },
  zt: {
    translation: ztText
  }
} as const;

i18n.use(initReactI18next).init({
  lng: DEFAULT_LOCALE,
  resources
});

function getLocaleParams() {
  const searchParams = new URLSearchParams(location.search);
  const lang = searchParams.get('lang') || '';
  if (lang) {
    return getValidLocale(lang);
  }
}

export function setLocale(locale: string) {
  i18n.changeLanguage(locale);
  document.documentElement.setAttribute('lang', locale);
  localStorage.setItem('locale', locale);
}

(() => {
  const defaultLocale =
    getLocaleParams() || localStorage.getItem('locale') || DEFAULT_LOCALE;
  setLocale(defaultLocale);
})();

export default i18n;
