export default {
  ticketing: {
    title: '東京晴空塔® 門票（指定時間入場券）',
    ticketPrice: '票價',
    content: '票券內容',
    purchaseNotice: '購買須知',
    cancellationPolicy: '取消須知',
    meetingPoint: '地點說明',
    inventoryNotice: '庫存須知',
    symbol: {
      plenty: '充足',
      few: '少量',
      none: '無庫存'
    },
    options: 'Options',
    time: 'Time',
    calendarMsg: `※<spanRed>{{redColor}}</spanRed>：套票／<spanBlue>{{blueColor}}</spanBlue>：天望甲板成人票價。
    ※最多可提前 30 天預訂。
    ※截至1天前購票最高立省JPY 400。`,
    redColor: '红字',
    blueColor: '藍字',
    noSlotsAvailable: '非常抱歉，当前没有票券庫存。',
    soldOut: '預定販售當日票'
  },
  date: {
    month: {
      month1: 'Jan',
      month2: 'Feb',
      month3: 'Mar',
      month4: 'Apr',
      month5: 'May',
      month6: 'Jun',
      month7: 'Jul',
      month8: 'Aug',
      month9: 'Sep',
      month10: 'Oct',
      month11: 'Nov',
      month12: 'Dec'
    },
    weeks: {
      sun: 'Sun.',
      mon: 'Mon.',
      tue: 'Tue.',
      wed: 'Wed.',
      thu: 'Thu.',
      fri: 'Fri.',
      sat: 'Sat.'
    }
  }
};
