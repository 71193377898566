import { makeObservable, action, observable, computed } from 'mobx';
import {
  activityService,
  GenericAvailabilityCalendar,
  GenericAvailabilityCalendarDailyAvailability,
  GenericActivity,
  AvailabilityCalendarPlanInformation
} from '@/services';
import {
  validTimeCheck,
  getCurrentDate,
  getTomorrowDate,
  OPTION
} from '@/utils';

const activityId = import.meta.env.VITE_APP_ACTIVITY_ID;
const monthActivityId = import.meta.env.VITE_APP_MONTH_ACTIVITY_ID;
const statusOK = 'OK';
const currencyJPY = 'JPY';
export class ActivityStore {
  @observable activity: GenericActivity | undefined = {};
  @observable todayActivity: GenericActivity | undefined = {};
  @observable availabilityCalendar: GenericAvailabilityCalendar | null = {};
  @observable todayAvailabilityCalendar: GenericAvailabilityCalendar | null =
    {};
  @observable
  availabilityCalendarDailyAvailability: GenericAvailabilityCalendarDailyAvailability | null =
    {};
  @observable tablePrice = {};

  private baseIndex = 0;
  private chosenDate = '';

  constructor() {
    makeObservable(this);
  }

  @action
  setActivity = (activity?: GenericActivity) => {
    this.activity = activity;
  };

  @action
  setTodayActivity = (todayActivity?: GenericActivity) => {
    this.todayActivity = todayActivity;
  };

  @action
  setAvailabilityCalendar = (
    availabilityCalendar: GenericAvailabilityCalendar
  ) => {
    this.availabilityCalendar = availabilityCalendar;
  };

  @action
  setTodayAvailabilityCalendar = (
    availabilityCalendar: GenericAvailabilityCalendar
  ) => {
    this.todayAvailabilityCalendar = availabilityCalendar;
  };

  @action
  setTablePrice = (tablePrice: {
    [key: string]: {
      price: {
        combo: number | undefined;
        deck: number | undefined;
      };
    };
  }) => {
    this.tablePrice = {
      ...this.tablePrice,
      ...tablePrice
    };
  };

  @action
  setAvailabilityCalendarDailyAvailability = (
    availabilityCalendarDailyAvailability: GenericAvailabilityCalendarDailyAvailability
  ) => {
    this.availabilityCalendarDailyAvailability =
      availabilityCalendarDailyAvailability;
  };

  @action
  fetchActivity = async (activityId: string, languageCode: string) => {
    const activityResp = await activityService.genericServiceGetActivity({
      body: {
        languageCode,
        activityId
      }
    });
    return activityResp?.activity;
  };

  @action
  fetchActivities = async ({ languageCode }: { languageCode: string }) => {
    const [activity, todayActivity] = await Promise.all([
      this.fetchActivity(monthActivityId, languageCode),
      this.fetchActivity(activityId, languageCode)
    ]);
    this.setActivity(activity);
    this.setTodayActivity(todayActivity);
  };

  @action
  fetchAvailabilityCalendar = async ({
    languageCode
  }: {
    languageCode: string;
  }) => {
    const availabilityCalendarResp =
      await activityService.genericServiceGetAvailabilityCalendar({
        body: {
          languageCode,
          activityId: monthActivityId
        }
      });
    if (availabilityCalendarResp?.availabilityCalendar) {
      this.setAvailabilityCalendar(
        availabilityCalendarResp.availabilityCalendar
      );
    }
  };

  @action
  fetchTodayAvailabilityCalendar = async ({
    languageCode
  }: {
    languageCode: string;
  }) => {
    const availabilityCalendarResp =
      await activityService.genericServiceGetAvailabilityCalendar({
        body: {
          languageCode,
          activityId,
          targetDateFrom: getCurrentDate(),
          targetDateTo: getTomorrowDate()
        }
      });
    if (availabilityCalendarResp?.availabilityCalendar) {
      this.setTodayAvailabilityCalendar(
        availabilityCalendarResp.availabilityCalendar
      );
    }
  };

  getPriceInfo = (
    planInfos: AvailabilityCalendarPlanInformation[] | undefined
  ) => {
    const [comboPlan, deckPlan] = planInfos || [];
    return {
      combo: comboPlan?.unitItems?.[this.baseIndex]?.originalAmount,
      deck: deckPlan?.unitItems?.[this.baseIndex]?.originalAmount
    };
  };

  fillPriceIntoTable = () => {
    const today = getCurrentDate();

    const [todayAvailability] =
      this.todayAvailabilityCalendar?.dailyAvailabilities ?? [];

    const todayAvailabilityPlanInfos = todayAvailability?.planInfos;

    if (todayAvailabilityPlanInfos?.length) {
      this.setTablePrice({
        [today]: {
          price: this.getPriceInfo(todayAvailabilityPlanInfos)
        }
      });
    }

    this.availabilityCalendar?.dailyAvailabilities?.forEach(
      ({ status, planInfos, date }) => {
        if (status === statusOK && date) {
          this.setTablePrice({
            [date]: {
              price: this.getPriceInfo(planInfos)
            }
          });
        }
      }
    );
  };

  @action
  fetchAvailabilityChosenDate = (chosenDate: string) => {
    this.chosenDate = chosenDate;

    const dailyAvailabilities =
      chosenDate === getCurrentDate()
        ? this.todayAvailabilityCalendar?.dailyAvailabilities
        : this.availabilityCalendar?.dailyAvailabilities;

    const [chosenAvailability] =
      dailyAvailabilities?.filter(dailyPlan => dailyPlan.date === chosenDate) ||
      [];

    chosenAvailability &&
      this.setAvailabilityCalendarDailyAvailability(chosenAvailability);
  };

  @action
  cleanActivity = () => {
    this.activity = {};
    this.availabilityCalendar = {};
  };

  @action
  checkedValidCards = () => {
    if (this.chosenDate === getCurrentDate())
      return this.availabilityCalendarDailyAvailability?.timeAvailabilities?.filter(
        availabileItem => validTimeCheck(availabileItem.time)
      );
    else {
      return this.availabilityCalendarDailyAvailability?.timeAvailabilities;
    }
  };

  @computed
  get activityOptions() {
    const availability = this.availabilityCalendarDailyAvailability;

    return (
      this.todayActivity?.plans?.map((plan, index) => {
        return {
          title: plan?.title ?? '',
          timeAvailabilities: this.checkedValidCards ?? [],
          amount:
            this.availabilityCalendarDailyAvailability?.planInfos?.[index]
              ?.unitItems?.[this.baseIndex]?.originalAmount ?? 0,
          date: availability?.date ?? '',
          planId:
            availability?.status === statusOK
              ? availability?.planInfos?.[index]?.planId
              : '',
          location:
            plan?.participationLocations?.[this.baseIndex]?.description ?? '',
          option: !index ? OPTION.COMBO : OPTION.DECK
        };
      }) || []
    );
  }

  @computed
  get cancelPolicies() {
    return this.activity?.cancelPolicies
      ?.filter(policy => policy.description)
      .map(policy => policy.description)
      .join('\n');
  }

  @computed
  get currencyCode() {
    return this.activity?.displayCurrencyCode ?? currencyJPY;
  }

  @computed
  get loadedCalendar() {
    return (
      this.todayAvailabilityCalendar?.dailyAvailabilities?.length &&
      this.availabilityCalendar?.dailyAvailabilities?.length
    );
  }
}

export default new ActivityStore();
