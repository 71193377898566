export const formatPrice = (price?: string | number) => {
  if (price) {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } else {
    return '';
  }
};

export const sleep = (ms: number) =>
  new Promise(resolve => setTimeout(resolve, ms));

export const flushPromises = () => sleep(0);

export function randomId() {
  return Math.random().toString(36).substring(2, 9);
}

export const convertDateToString = (dateObj: Date): string => {
  const month =
    dateObj.getMonth() < 9
      ? `0${dateObj.getMonth() + 1}`
      : `${dateObj.getMonth() + 1}`;
  const date =
    dateObj.getDate() < 10 ? `0${dateObj.getDate()}` : dateObj.getDate();
  return `${dateObj.getFullYear()}-${month}-${date}`;
};

export const formatter = new Intl.NumberFormat('ja-JP', {
  style: 'currency',
  currency: 'JPY'
});

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = () => {};

export const eventKey = {
  Escape: 'Escape',
  Enter: 'Enter',
  Space: ' ',
  ArrowLeft: 'ArrowLeft',
  ArrowUp: 'ArrowUp',
  ArrowRight: 'ArrowRight',
  ArrowDown: 'ArrowDown',
  Tab: 'Tab',
  Backspace: 'Backspace'
} as const;

export const mobilePattern =
  /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i;

export const dateObj = new Date();

const currentTimeInMinutes = 60 * dateObj.getHours() + dateObj.getMinutes();

export const validTimeCheck = (time: string | undefined): boolean => {
  const regex = new RegExp('^([0-1][0-9]|2[0-3]):[0-5][0-9]$'); // # Match time HH:MM format

  if (time && regex.test(time)) {
    const [hour, minute] = (time && time.split(':', 2)) as string[];

    return 60 * Number(hour) + Number(minute) > currentTimeInMinutes;
  }
  return true;
};

export const URL_REGEX =
  /(https?|http):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%?=~_|]/g;
