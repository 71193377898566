import { GenericApi } from '@linktivity/linkapis';

const BASE_PATH = import.meta.env.VITE_APP_BASE_PATH;

const config = new GenericApi.Configuration({
  basePath: BASE_PATH,
  middleware: [
    {
      pre: async context => {
        const requestHeaders: HeadersInit = new Headers({
          'Content-Type': 'application/json',
          'Grpc-Metadata-Supplier': import.meta.env.VITE_APP_SUPPLIER,
          'X-Origin': import.meta.env.VITE_APP_REDIRECT_BASEURL
        });

        context.init.headers = requestHeaders;
      },
      post: async context => {
        if (!context.response.ok) {
          const data = await context.response.json();
          console.error(data);
          if (context.response.status === 401) {
            localStorage.clear();
          }
        }
      }
    }
  ]
});

export default config;
