import { createContext, useContext } from 'react';
import activityStore, { ActivityStore } from './activityStore';

export type RootStore = {
  activityStore: ActivityStore;
};

export const store: RootStore = {
  activityStore: activityStore
};

const StoreContext = createContext<RootStore>(store);

export const StoreProvider = StoreContext.Provider;

export const useStore = () => useContext(StoreContext);

export { activityStore };
