export default {
  ticketing: {
    title: '도쿄 스카이트리® (날짜/시간 지정 입장권)',
    ticketPrice: '티켓 가격',
    content: '티켓 내용',
    purchaseNotice: '구매 공지',
    cancellationPolicy: '취소 정책',
    meetingPoint: '장소 설명',
    inventoryNotice: '재고 공지',
    symbol: {
      plenty: '풍부한',
      few: '약간의',
      none: '사용 불가'
    },
    options: 'Options',
    time: 'Time',
    calendarMsg: `*<spanRed>{{redColor}}</spanRed> : 세트권 / <spanBlue>{{blueColor}}</spanBlue> : 덴보 데크 350m 어른 요금.
    *30일 후까지 예약이 가능합니다.
    *참가 전날까지 구입 시 최대 400엔 할인받으실 수 있습니다.`,
    redColor: '빨강 글씨',
    blueColor: '파랑 글씨',
    noSlotsAvailable: '죄송합니다, 현재 티켓 재고가 없습니다.',
    soldOut: '당일권 판매 예정'
  },
  date: {
    month: {
      month1: 'Jan',
      month2: 'Feb',
      month3: 'Mar',
      month4: 'Apr',
      month5: 'May',
      month6: 'Jun',
      month7: 'Jul',
      month8: 'Aug',
      month9: 'Sep',
      month10: 'Oct',
      month11: 'Nov',
      month12: 'Dec'
    },
    weeks: {
      sun: 'Sun.',
      mon: 'Mon.',
      tue: 'Tue.',
      wed: 'Wed.',
      thu: 'Thu.',
      fri: 'Fri.',
      sat: 'Sat.'
    }
  }
};
