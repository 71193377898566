export default {
  ticketing: {
    title: 'Tokyo Skytree® Admission Ticket (for specified date and time)',
    ticketPrice: 'Ticket Price',
    content: 'Ticket Content',
    purchaseNotice: 'Purchase Notice',
    cancellationPolicy: 'Cancellation Policy',
    meetingPoint: 'Meeting Point',
    inventoryNotice: 'Inventory Notice',
    symbol: {
      plenty: 'Plenty',
      few: 'Few',
      none: 'Not Available'
    },
    options: 'Options',
    time: 'Time',
    calendarMsg: `- Combo tickets (<spanRed>{{redColor}}</spanRed>) / Adult prices for the Tembo Deck (<spanBlue>{{blueColor}}</spanBlue>).
    - Reservations are available up to 30 days in advance.
    - Purchase by the day before and save up to 400 yen.`,
    redColor: 'in red',
    blueColor: 'in blue',
    noSlotsAvailable: 'Sorry, no tickets available in the inventory.',
    soldOut: 'Same-day ticket will be sold'
  },
  date: {
    month: {
      month1: 'Jan',
      month2: 'Feb',
      month3: 'Mar',
      month4: 'Apr',
      month5: 'May',
      month6: 'Jun',
      month7: 'Jul',
      month8: 'Aug',
      month9: 'Sep',
      month10: 'Oct',
      month11: 'Nov',
      month12: 'Dec'
    },
    weeks: {
      sun: 'Sun.',
      mon: 'Mon.',
      tue: 'Tue.',
      wed: 'Wed.',
      thu: 'Thu.',
      fri: 'Fri.',
      sat: 'Sat.'
    }
  }
};
