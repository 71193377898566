import React, { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cls from 'clsx';
import { setLocale } from '@/i18n';
import { Dropdown } from '@/components';
import { ReactComponent as SkytreeLogo } from '@/assets/skytree_logo.svg';
import { ReactComponent as Globe } from '@/assets/icons/globe.svg';
import logo from '@/assets/logo.png';
import { useScrollLock } from '@/hooks/useScrollLock';
import { useFocusTrap } from '@/hooks/useFocusTrap';
import { useWindowEvent } from '@/hooks/useWindowEvent';
import { eventKey } from '@/constants/keyboard';
import styles from './header.module.css';

const i18nList = [
  {
    value: 'en',
    label: 'English'
  },
  {
    value: 'zh',
    label: '简体中文'
  },
  {
    value: 'zt',
    label: '繁體中文'
  },
  {
    value: 'ko',
    label: '한국어'
  }
];

const Header: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [showLang, setShowLang] = useState(false);
  const { i18n } = useTranslation();

  const currentLocale = useMemo(
    () => i18nList.find(lang => lang.value === i18n.language)?.label,
    [i18n.language]
  );

  const changeLang = useCallback((locale: string) => {
    setLocale(locale);
    setOpen(false);
  }, []);

  const handleClose = () => setOpen(false);

  useScrollLock(open);

  useWindowEvent('keydown', event => {
    if (event.key === eventKey.Escape && open) {
      handleClose();
    }
  });

  const focusTrapRef = useFocusTrap(open);

  const langButton = useMemo(
    () => (
      <button
        className={styles.langButton}
        onClick={() => setShowLang(visible => !visible)}
      >
        <Globe className={styles.langButtonIcon} />
        <span>{currentLocale}</span>
      </button>
    ),
    [currentLocale]
  );

  return (
    <header className={styles.header}>
      <div className={styles.inner}>
        <div className={styles.skytree}>
          <SkytreeLogo height={40} />
        </div>
        <img src={logo} width={160} alt="header logo" className={styles.logo} />
        <div
          className={cls(styles.info, {
            [styles.open]: open
          })}
          ref={focusTrapRef}
        >
          <Dropdown
            target={langButton}
            visible={showLang}
            sameWidth={false}
            onClose={() => setShowLang(false)}
          >
            <Dropdown.Menu className={styles.dropdownMenu}>
              {i18nList.map(lang => (
                <Dropdown.Item key={lang.value}>
                  <button
                    className={styles.dropdownButton}
                    onClick={() => changeLang(lang.value)}
                  >
                    {lang.label}
                  </button>
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </header>
  );
};

export default memo(Header);
